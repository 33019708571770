import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ArrowDown, DocumentDownload, Eye } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import * as utils from "../../../Utils/comman";
import CustomPagination from "../../../Utils/CustomPagination";
function EmpTravelList({ selectedMonth, props }) {
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [expenses, setExpenses] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const userRole = userProfile?.user_role[0]?.roleName;
  const location = useLocation();
  const [csvData, setCsvData] = useState([]);
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const primaryMenuRes = useSelector(
      (state) => state?.primaryMenu?.primaryMenu?.permissions
    );

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
    props.setemp(row);
    props.setHrAccountant(true);
  });

  useEffect(() => {
    if (primaryMenuRes) {
      if (utils.hasPermission(
        primaryMenuRes,
        "All Expenses",
        "all.expenses"
      ) || utils.hasPermission(
        primaryMenuRes,
        "Accountant Expenses",
        "accountant.expenses"
      )) {
        getEmpListExpense();
      } else {
        getEmpList();
      }
    }
  }, [primaryMenuRes, page, selectedMonth]);

  const getEmpListExpense = () => {
    utils
      .callAPI(
        "get",
        `/expense/emp-list-expense?type=travel&month=${moment(
          selectedMonth
        ).format("MMMM-YYYY")}`
      )
      .then((res) => {
        if (res) {
          setExpenses(res);
        } else {
          setExpenses([]);
        }
      })
      .catch(() => {
        setExpenses([]);
      });
  };

  const getEmpList = () => {
    utils
      .callAPI("get", "/expense/emp-list")
      .then((res) => {
        if (res) {
          setExpenses(res.result);
        } else {
          setExpenses([]);
        }
      })
      .catch(() => {
        setExpenses([]);
      });
  };
  const columns = [
    {
      name: "Employee Code",
      selector: (row) => row.emp_code,
    },
    {
      name: "Employee Name",
      selector: (row) => row.emp_name,
    },
    {
      name: "Approved Amount",
      selector: (row) => parseFloat(row.total_amt).toFixed(2),
      omit: location.pathname === "/expenses/team-expense",
    },
    {
      name: "Total Amount",
      selector: (row) => parseFloat(row.expense_amt).toFixed(2),
      omit: location.pathname !== "/expenses/all-expense",
    },
    {
      name: "Status",
      selector: (row) =>
        parseFloat(row.hr_submit) == 1 ? "Approved" : "Pending",
      omit: location.pathname !== "/expenses/all-expense",
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.Button className="btn btn-flat ms-1" onClick={handleView(row)}>
            <Eye />
          </RB.Button>
        </>
      ),
      omit: location.pathname === "/expenses/accountant-expense",
    },
  ];

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [active]);

  const filteredItems = useMemo(() => {
    return expenses.filter((item) => {
      const statusText = item.hr_submit === 1 ? "Approved" : "Pending";
      const searchValue = filterText.toLowerCase();

      return (
        item.emp_code.toLowerCase().includes(searchValue) ||
        item.emp_name.toLowerCase().includes(searchValue) ||
        statusText.toLowerCase().includes(searchValue)
      );
    });
  }, [expenses, filterText]);

  const handleDownload = () => {
    const csvHeader = `Sr.No.,Emp. Code,Employee Name,Amount,Account No.`;
    const isAccountant = location.pathname == "/expenses/accountant-expense";
    const isHr = location.pathname == "/expenses/all-expense";
    const dataToExport = isAccountant ? expenses : filteredItems;
    const csvRows = dataToExport.map((expense, index) => {
      return `${index + 1},${expense.emp_code},${expense.emp_name},${parseFloat(
        expense.total_amt
      ).toFixed(2)},${parseFloat(expense.bank_account_no)}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");
    // setCsvData(csvContent);
    // setActive(true);
    generateCSV(csvContent)
  };

  function generateCSV(csvContent) {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${moment(selectedMonth).format(
      "MMMM-YYYY"
    )}_LocalExpense.csv`;
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="d-flex align-items-center">
        {location.pathname == "/expenses/all-expense" ? (
          <>
            <input
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="form-control me-2"
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const paginatedExpenses = useMemo(() => {
    if (!Array.isArray(filteredItems)) {
      return [];
    }

    const startIndex = (page - 1) * countPerPage;
    const endIndex = startIndex + countPerPage;

    return filteredItems?.slice(startIndex, endIndex);
  }, [filteredItems, page, countPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div>
        <a
            href
            className={`${
              paginatedExpenses?.length == 0 ? "disabled" : ""
            } btn btn-primary btn-circle me-2`}
            variant="primary"
            onClick={() => handleDownload()}
          >
            <DocumentDownload />
          </a>
          <CSVLink
            data={csvData}
            ref={csvLink}
            filename={`travelExpense_${moment(selectedMonth).format(
              "MMMM-YYYY"
            )}.csv`}
          />
        </div>
      </div>

      <DataTable
        columns={columns}
        data={paginatedExpenses}
        highlightOnHover
        responsive
        pagination
        paginationServer
        paginationTotalRows={filteredItems.length}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        paginationComponent={() => (
          <CustomPagination
            currentPage={page}
            totalRows={filteredItems?.length}
            rowsPerPage={countPerPage}
            onPageChange={handlePageChange}
          />
        )}
        onChangePage={(page) => setPage(page)}
        subHeader={location.pathname == "/expenses/all-expense"}
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={{
          head: {
            style: {
              fontSize: "0.875rem",
              fontWeight: "bold",
            },
          },
        }}
      />
    </>
  );
}

export default EmpTravelList;
