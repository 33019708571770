import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import { useSelector } from "react-redux";
import * as utils from "../../Utils/comman";

function WithdrawalAccess() {
  const [isWithdrawalEnabled, setIsWithdrawalEnabled] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  useEffect(() => {
    getWithdrawalStatus();
  }, []);

  const getWithdrawalStatus = () => {
    utils
      .callAPI("get", `/other/withdrawal-list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setIsWithdrawalEnabled(res.meta_value === "true");
          // console.log(res)
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleToggle = () => {
    const updatedStatus = !isWithdrawalEnabled;

    const formData = new FormData();
    formData.append("withdrawal_stop", updatedStatus.toString());

    utils
      .callAPI("post", `/other/withdrawal-save`, formData)
      .then((res) => {
        getWithdrawalStatus();
      })
      .catch((err) => {});
  };

  return (
    <>
      {/* {utils.hasPermission(
        primaryMenuRes,
        "Dashboard",
        "dashboard.withdrawal"
      ) && ( */}
      <RB.Col className="col-12 col-md-8 col-lg-6">
        <RB.Card className="">
          <RB.Card.Header>
            <h4>Withdrawal Access</h4>
            <div className="card-header-action"></div>
          </RB.Card.Header>

          <RB.Card.Body className="mb-0">
            <RB.Form>
              <RB.Form.Group
                controlId="withdrawalSwitch"
                className="d-flex align-items-center justify-content-start"
              >
                <RB.Form.Check
                  type="switch"
                  checked={isWithdrawalEnabled}
                  onChange={handleToggle}
                  className="withdrawal-switch ms-2 me-3"
                  // label={isWithdrawalEnabled ? "On" : "Off"}
                />
                <RB.Form.Label className="mb-0 withdrawal-label">
                  {isWithdrawalEnabled ? "On" : "Off"}{" "}
                  <span className={isWithdrawalEnabled ? "" : "ms-1"}>
                    Withdrawal
                  </span>
                </RB.Form.Label>
              </RB.Form.Group>
            </RB.Form>
          </RB.Card.Body>
        </RB.Card>
      </RB.Col>
      {/* )}  */}
    </>
  );
}

export default WithdrawalAccess;
