import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, setUserData } from "../../reducers/userProfileSlice";
import * as utils from "../../Utils/comman";
import noImage from "../../assets/images/no_image.jpg";
function UserProfile() {
	const dispatch = useDispatch();
	const userProfile = useSelector((state) => state.userProfile.userProfile);

	const [formData, setFormData] = useState({
		id: userProfile?.id || "",
		firstName: userProfile?.firstName || "",
		middleName: userProfile?.middleName || "",
		lastName: userProfile?.lastName || "",
		mobileNo: userProfile?.mobileNo || "",
		email: userProfile?.email || "",
		isActive: userProfile?.isActive || "",
		roleId: userProfile?.user_role.length > 0 ? userProfile?.user_role[0].id : "",
	});
	const [validated, setValidated] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [role, setRole] = useState([]);
	const [tempStudentProfilePic, setTempStudentProfile] = useState(
		userProfile?.profilePic_path ? userProfile?.profilePic_path : noImage
	);
	const [studentProfilePic, setStudentProfilePic] = useState("");
	const [validateMobile, setValidateMobile] = useState(false);
	const [uploadError, setUploadError] = useState("");
	const status = [
		{ id: "", name: "Select an option..." },
		{ id: "1", name: "Active" },
		{ id: "0", name: "Inactive" },
	];

	useEffect(() => {
		if (userProfile) {
			setFormData({
				id: userProfile?.id || "",
				firstName: userProfile?.firstName || "",
				middleName: userProfile?.middleName || "",
				lastName: userProfile?.lastName || "",
				mobileNo: userProfile?.mobileNo || "",
				email: userProfile?.email || "",
				isActive: userProfile?.isActive || "",
				roleId:
					userProfile?.user_role.length > 0 ? userProfile?.user_role[0].id : "",
			});
		}
	}, [userProfile]);

	const handleStudentUpload = (e) => {
		var link = document.getElementById("studentProfilePic");
		link.click();
	};
	const handleStudentFileChange = (e) => {
		setUploadError("");
		const file = e.target.files[0];
		if (!file) return;
	
		const acceptedExtensions = ["jpg", "jpeg", "png"];
		const fileExtension = file.name.split(".").pop().toLowerCase();
		const mimeType = file.type;
	
		if (
		  !acceptedExtensions.includes(fileExtension) ||
		  !mimeType.startsWith("image/")
		) {
		  setUploadError("Please select a valid image file (JPG, JPEG, PNG).");
		  return;
		}
	
		setTempStudentProfile(URL.createObjectURL(file));
		setStudentProfilePic(file);
	  };

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		let validatedValue = value;

		if (name === "mobileNo") {
			validatedValue = value.replace(/\D/g, "");

			setValidateMobile(validatedValue.length !== 10);
		}
		setFormData({
			...formData,
			[name]: validatedValue,
		});
	};

	useEffect(() => {
		getRoleList();
	}, []);

	const getRoleList = () => {
		utils
			.callAPI("get", `/role/list`)
			.then((res) => {
				if (typeof res !== "undefined") {
					setRole([{ id: "", roleName: "Select an option..." }, ...res.result]);
				} else {
					setRole([]);
				}
			})
			.catch((err) => {
				setRole([]);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
		} else {
			// if (validateMobile) {
			//   return;
			// }
			setValidateMobile(false);
			setIsSubmit(true);
		}
	};

	useEffect(() => {
		if (isSubmit) {
			var bodyFormData = new FormData();
			bodyFormData.append("id", formData.id);
			bodyFormData.append("firstName", formData.firstName);
			bodyFormData.append("middleName", formData.middleName);
			bodyFormData.append("lastName", formData.lastName);
			bodyFormData.append("mobileNo", formData.mobileNo);
			bodyFormData.append("email", formData.email);
			bodyFormData.append("isActive", formData.isActive);
			bodyFormData.append("roleId", formData.roleId);
			bodyFormData.append("profilePic", studentProfilePic);
			//   if (!isEdit) {
			//     bodyFormData.append("password", "Test@123");
			//   }

			utils
				.callAPI("post", `/users/users-edit`, bodyFormData)
				.then((res) => {
					if (typeof res !== "undefined") {
						dispatch(fetchUserProfile());
						setIsSubmit(false);
					}
				})
				.catch((err) => {});
		}
	}, [isSubmit]);
	return (
		<Layout>
			<div className="main-content">
				<section className="section">
					<div className="section-header">
						<h1>Profile</h1>
						<div className="ms-auto">
							<Link to="/dashboard" className="text-underline me-2">
								Back
							</Link>
						</div>
					</div>
					<div className="section-body">
						<RB.Card className="">
							{/* <RB.Card.Header><h4>User Profile</h4></RB.Card.Header> */}
							<RB.Card.Body>
								<RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
									<RB.Row>
										<RB.Col md={3}>
											<RB.Row>
												<div className="col-md-10">
													<div className="profile-photo rounded mb-3">
														<img
															src={tempStudentProfilePic}
															className="img-fluid rounded"
															id="img_profile4"
															alt="profile"
														/>
																					 {uploadError && (
                            <span className="text-danger">{uploadError}</span>
                          )}
													</div>
													<a
														className="btn btn-block text-underline upload-button"
														id="btn-f-upload"
														onClick={handleStudentUpload}>
														Upload Photo
													</a>
													<input
														id="studentProfilePic"
														type="file"
														className="form-control d-none"
														name="studentProfilePic"
														accept=".jpg,.jpeg,.png"
														onChange={handleStudentFileChange}
													/>
												</div>
											</RB.Row>
										</RB.Col>
										<RB.Col md={9}>
											<RB.Row>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formFirstName"
															label="First Name"
															className="floating-label-fancy">
															<RB.Form.Control
																type="text"
																placeholder="Enter First Name"
																name="firstName"
																value={formData.firstName}
																onChange={handleInputChange}
																required
																maxLength={32}
																minLength={2}
															/>
															<RB.Form.Control.Feedback type="invalid">
																Please enter first name.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formMiddleName"
															label="Middle Name"
															className="floating-label-fancy">
															<RB.Form.Control
																type="text"
																placeholder="Enter Middle Name"
																name="middleName"
																value={formData.middleName}
																onChange={handleInputChange}
																required
																maxLength={32}
															/>
															<RB.Form.Control.Feedback type="invalid">
																Please enter middle name.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
											</RB.Row>
											<RB.Row>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formLastName"
															label="Last Name"
															className="floating-label-fancy">
															<RB.Form.Control
																type="text"
																placeholder="Enter Last Name"
																name="lastName"
																value={formData.lastName}
																onChange={handleInputChange}
																maxLength={32}
																minLength={2}
																required
															/>
															<RB.Form.Control.Feedback type="invalid">
																Please enter last name.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formMobileNo"
															label="Mobile No"
															className="floating-label-fancy">
															<RB.Form.Control
																type="text"
																placeholder="Enter Mobile No"
																name="mobileNo"
																value={formData.mobileNo}
																maxLength={10}
																onChange={handleInputChange}
															/>
															{validateMobile ? (
																<span className="text-danger">
																	{" "}
																	Please enter a valid mobileNo.
																</span>
															) : (
																""
															)}
														</RB.FloatingLabel>
													</div>
												</RB.Col>
											</RB.Row>
											<RB.Row>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formEmail"
															label="Email"
															className="floating-label-fancy">
															<RB.Form.Control
																type="email"
																placeholder="Enter Email"
																name="email"
																value={formData.email}
																onChange={handleInputChange}
																disabled
																required
															/>
															<RB.Form.Control.Feedback type="invalid">
																Please provide email.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formIsActive"
															label="Status"
															className="floating-label-fancy">
															<RB.Form.Control
																as="select"
																name="isActive"
																value={formData.isActive}
																onChange={handleInputChange}
																required
																disabled>
																{status.map((role) => (
																	<option key={role.id} value={role.id}>
																		{role.name}
																	</option>
																))}
															</RB.Form.Control>
															<RB.Form.Control.Feedback type="invalid">
																Please select option for IsActive.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
											</RB.Row>
											<RB.Row>
												<RB.Col md={6}>
													<div className="mb-3">
														<RB.FloatingLabel
															controlId="formRoleId"
															label="Designation"
															className="floating-label-fancy">
															<RB.Form.Control
																as="select"
																name="roleId"
																className="form-select"
																value={formData.roleId}
																onChange={handleInputChange}
																required
																disabled>
																{role.map((role) => (
																	<option key={role.id} value={role.id}>
																		{role.roleName}
																	</option>
																))}
															</RB.Form.Control>
															<RB.Form.Control.Feedback type="invalid">
																Please select option for designation.
															</RB.Form.Control.Feedback>
														</RB.FloatingLabel>
													</div>
												</RB.Col>
											</RB.Row>
										</RB.Col>
									</RB.Row>

									<RB.Row className="justify-content-end">
										<RB.Col className="col-auto">
											<RB.Button variant="primary" type="submit">
												Update
											</RB.Button>
										</RB.Col>
									</RB.Row>
								</RB.Form>
							</RB.Card.Body>
						</RB.Card>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default UserProfile;
