import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as utils from "../../../Utils/comman";
import { AddCircle, CloseCircle, Eye, TickCircle } from "iconsax-react";
import { setAction } from "../../../reducers/actionSlice";
import TeamExpensesList from "../Local/TeamExpenseList";
import LocalExpView from "./LocalExpView";
import ReasonModal from "../../ReasonModal";
import CustomPagination from "../../../Utils/CustomPagination";

function TravelPlanList({
  setshowApproveExpense,
  setTraveplan,
  emp,
  setLocal,
  local,
  showApproveExpense,
  hrAccountant,
  currenttab,
}) {
  const [travelList, setTravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [localData, setLocaldata] = useState([]);
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [rejectReasonError, setRejectReasonError] = useState("");
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const userRole = userProfile?.user_role[0]?.roleName;
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const primaryMenuRes = useSelector(
      (state) => state?.primaryMenu?.primaryMenu?.permissions
    );
    
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );
  useEffect(() => {
    if (emp?.userId) {
      const isAccountantExpensePage =
        location.pathname === "/expenses/accountant-expense";

      let travelApiUrl = isAccountantExpensePage
        ? `expense/team-list-accountant?type=travel`
        : `/expense/emp-travel-list?type=travel&user_id=${emp?.userId}`;

      let localApiUrl = isAccountantExpensePage
        ? `expense/team-list-accountant?&type=local`
        : `/expense/emp-travel-list?type=local&user_id=${emp?.userId}`;

      if (utils.hasPermission(primaryMenuRes, "All Expenses", "all.expenses")) {
        localApiUrl = `/expense/all-list-hr?type=local&user_id=${emp?.userId}`;
        travelApiUrl = `/expense/all-list-hr?type=travel&user_id=${emp?.userId}`;
      }

      getTravelList(travelApiUrl);
      getLocalList(localApiUrl);
    }
  }, [
    page,
    emp?.userId,
    location.pathname,
    selectedStatus,
    showApproveExpense,
    local,
  ]);

  const getTravelList = (apiUrl) => {
    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          setTravelList(res);
        } else {
          setTravelList([]);
        }
      })
      .catch((err) => {
        setTravelList([]);
      });
  };

  const getLocalList = (apiUrl) => {
    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          setLocaldata(res);
        } else {
          setLocaldata([]);
        }
      })
      .catch((err) => {
        setLocaldata([]);
      });
  };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    // {
    //   name: <div>Type</div>,
    //   selector: (row) =>
    //     row.type === "area_visit" ? "Area Wise" : "Distributor Wise",
    // },
    {
      name: <div>Senior Status</div>,
      selector: (row) => row.exp_approval,
    },
    {
      name: <div>Hr Status</div>,
      selector: (row) => {
        if (row.hr_submit === null || row.hr_submit === undefined) {
          return "";
        } else if (row.hr_submit === "0") {
          return "Pending";
        } else if (row.hr_submit === "1") {
          return "Approved";
        } else {
          return "";
        }
      },
    },

    {
      name: <div>Hr Remark</div>,
      selector: (row) => row.hr_remark,
      cell: (row) => {
        const wordCount = row?.hr_remark?.split(" ").length;
        return wordCount > 3 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("HR Remark", row.hr_remark)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.hr_remark}</span>
        );
      },
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {location.pathname === "/expenses/all-expense" ? (
            // Show the button only if exp_approval is "Approve"
            row.exp_approval === "Approve" && (
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleViewExpense(row)}
              >
                <Eye />
              </RB.Button>
            )
          ) : (
            // For all other paths, show the button as it is
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleViewExpense(row)}
              >
                <Eye />
              </RB.Button>
            </>
          )}
          {location.pathname === "/expenses/accountant-expense" && (
            <>
              {row.account_status == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={handleApprove(row)}
                >
                  <TickCircle />
                </RB.Button>
              )}
              {row.account_status == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={handleReject(row)}
                >
                  <CloseCircle />
                </RB.Button>
              )}
            </>
          )}
        </>
      ),
    },
  ];
  console.log(hrAccountant, currenttab);
  const handleViewExpense = useCallback((row) => async () => {
    setshowApproveExpense(true);
    setTraveplan(row);
  });

  const filteredData = useMemo(() => {
    return (
      travelList.result?.filter((item) => {
        const filterTextLower = filterText.toLowerCase();
        return (
          item.approvalStatus === "Approved" &&
          (item.requestId.toLowerCase().includes(filterTextLower) ||
            item.employeeId.toLowerCase().includes(filterTextLower) ||
            item.destination.toLowerCase().includes(filterTextLower))
        );
      }) || []
    );
  }, [filterText, travelList]);

  //   const handleAddClick = (e) => {
  //     const propdata = {
  //       mode: "add",
  //     };
  //     props.onChange(propdata);
  //   };

  // const statusOptions = ["", "Pending", "Approve", "Reject"];

  // const handleStatusChange = (e) => {
  //   setSelectedStatus(e.target.value);
  // };

  const handleApprove = useCallback((row) => async () => {
    const formData = new FormData();
    formData.append("type", "travel");
    formData.append("id", row.id);
    formData.append("status", "Approve");
    formData.append("reason", "");

    utils
      .callAPI("post", "/expense/accountant-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const isAccountantExpensePage =
            location.pathname === "/expenses/accountant-expense";

          getTravelList(isAccountantExpensePage);
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleReject = useCallback((row) => async () => {
    setSelectedExpense(row);
    setShowRejectModal(true);
  });

  const handleRejectSubmit = async () => {
    if (!rejectReason.trim()) {
      setRejectReasonError("Reject reason cannot be blank");
      return;
    }
    const formData = new FormData();
    formData.append("type", "travel");
    formData.append("id", selectedExpense.id);
    formData.append("status", "Reject");
    formData.append("reason", rejectReason);
    utils
      .callAPI("post", "/expense/accountant-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const isAccountantExpensePage =
            location.pathname === "/expenses/accountant-expense";

          getTravelList(isAccountantExpensePage);
          setShowRejectModal(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  const statusOptions = ["", "Pending", "Approve", "Reject"];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      {local ? (
        <>
          <LocalExpView data={data} setLocal={setLocal} />
        </>
      ) : (
        <>
          {hrAccountant ? (
            <>
              <div className="section-body">
                {currenttab == "Travel" ? (
                  <>
                    <RB.Card className="card shadow">
                      <RB.Card.Header>
                        <h4>Travel Plans</h4>
                        <div className="card-header-action"></div>
                      </RB.Card.Header>
                      <RB.Card.Body>
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          highlightOnHover
                          responsive
                          striped
                          pagination
                          paginationServer
                          paginationTotalRows={travelList.total}
                          paginationPerPage={countPerPage}
                          paginationComponentOptions={{
                            noRowsPerPage: true,
                          }}
                          paginationComponent={() => (
                            <CustomPagination
                              currentPage={page}
                              totalRows={travelList.total}
                              rowsPerPage={countPerPage}
                              onPageChange={handlePageChange}
                            />
                          )}
                          onChangePage={(page) => setPage(page)}
                          persistTableHead
                          customStyles={{
                            head: {
                              style: {
                                fontSize: "0.875rem", // Adjust the font size as needed
                                fontWeight: "bold", // Adjust the font weight as needed
                              },
                            },
                          }}
                        />
                        {/* )} */}
                      </RB.Card.Body>
                    </RB.Card>
                  </>
                ) : (
                  <>
                    <TeamExpensesList
                      setLocal={setLocal}
                      setData={setData}
                      localData={localData}
                      getLocalList={getLocalList}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="section-body">
                <RB.Card className="card shadow">
                  <RB.Card.Header>
                    <h4>Travel Plans</h4>
                    <div className="card-header-action"></div>
                  </RB.Card.Header>
                  <RB.Card.Body>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      highlightOnHover
                      responsive
                      striped
                      pagination
                      paginationServer
                      paginationTotalRows={travelList.total}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      paginationComponent={() => (
                        <CustomPagination
                          currentPage={page}
                          totalRows={travelList.total}
                          rowsPerPage={countPerPage}
                          onPageChange={handlePageChange}
                        />
                      )}
                      onChangePage={(page) => setPage(page)}
                      persistTableHead
                      customStyles={{
                        head: {
                          style: {
                            fontSize: "0.875rem", // Adjust the font size as needed
                            fontWeight: "bold", // Adjust the font weight as needed
                          },
                        },
                      }}
                    />
                    {/* )} */}
                  </RB.Card.Body>
                </RB.Card>

                <TeamExpensesList
                  setLocal={setLocal}
                  setData={setData}
                  localData={localData}
                  getLocalList={getLocalList}
                />
              </div>
            </>
          )}
        </>
      )}
      <RB.Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Enter Reject Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Form.Control
            as="textarea"
            placeholder="Enter reject reason..."
            value={rejectReason}
            onChange={(e) => {
              setRejectReason(e.target.value);
              setRejectReasonError("");
            }}
          />
          {rejectReasonError && (
            <div className="text-danger mt-2">{rejectReasonError}</div>
          )}
        </RB.Modal.Body>

        <RB.Modal.Footer>
          <RB.Button
            variant="secondary"
            onClick={() => setShowRejectModal(false)}
          >
            Close
          </RB.Button>
          <RB.Button variant="danger" onClick={handleRejectSubmit}>
            Reject
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default TravelPlanList;
