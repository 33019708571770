import React, { useEffect, useState } from "react";
import TeamExpensesList from "../Components/TravelExpenses/TeamExpensesList";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import ExpensesList from "../Components/TravelExpense/ExpensesList";
import TeamExpenseList from "../Components/TravelExpense/TeamExpense/TeamEmpList";
import * as RB from "react-bootstrap";
import TravelPlanList from "../Components/TravelExpense/TeamExpense/TravelPlanList";
import TravelPlanSceduleList from "../Components/TravelExpense/Travel/TravelPlanSceduleList";
import { useSelector } from "react-redux";
import TabList from "../Components/TravelExpense/Hr&Accountant/TabList";
import * as utils from "../Utils/comman";

function TeamExpense() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();
  const [showApproveExpense, setshowApproveExpense] = useState(false);
  const [travelPlan, setTraveplan] = useState({});
  const [emp, setemp] = useState();
  const [local, setLocal] = useState(false);
  const [isAccountant, setIsAccountant] = useState(false);
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const userRole = userProfile?.user_role[0]?.roleName;
  const [currenttab, setCurrentTab] = useState("Travel");
  const [hrAccountant, setHrAccountant] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  useEffect(() => {
    if (location.pathname === "/expenses/accountant-expense") {
      setIsAccountant(true);
    } else {
      setIsAccountant(false);
    }
  }, [location]);

  useEffect(() => {
    setComp("list");
  }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  const handleBack = () => {
    if (showApproveExpense) {
      hanldeShow();
    } else {
      if (local) {
        setLocal(false);
        setComp("view");
      } else {
        setComp("add");
        setemp();
      }
    }
  };

  const hanldeShow = () => {
    setshowApproveExpense(!showApproveExpense);
  };
  console.log(primaryMenuRes);
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>
              {utils.hasPermission(
                primaryMenuRes,
                "All Expenses",
                "all.expenses"
              )
                ? "All Expenses"
                : isAccountant
                ? "Accountant Expenses"
                : "Team Expenses"}
            </h1>
          </div>
          <div className="section-body">
            <RB.Card className="card shadow ">
              <RB.Card.Header>
                <h4>{emp ? `${emp?.emp_name}` : "Employee List"}</h4>
                <div className="card-header-action">
                  {comp == "view" ? (
                    <RB.Button
                      className="btn btn-underline text-dark"
                      onClick={handleBack}
                    >
                      Back
                    </RB.Button>
                  ) : (
                    ""
                  )}
                </div>
              </RB.Card.Header>
              <RB.Card.Body>
                {comp == "view" ? (
                  <>
                    {!showApproveExpense ? (
                      <>
                        <TravelPlanList
                          setshowApproveExpense={hanldeShow}
                          setTraveplan={setTraveplan}
                          emp={emp}
                          setLocal={setLocal}
                          local={local}
                          showApproveExpense={showApproveExpense}
                          currenttab={currenttab}
                          hrAccountant={hrAccountant}
                        />
                      </>
                    ) : (
                      <>
                        <TravelPlanSceduleList
                          travelPlan={travelPlan}
                          setshowApproveExpense={setshowApproveExpense}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {utils.hasPermission(
                      primaryMenuRes,
                      "All Expenses",
                      "all.expenses"
                    ) ||
                    utils.hasPermission(
                      primaryMenuRes,
                      "Accountant Expenses",
                      "accountant.expenses"
                    ) ? (
                      <>
                        <TabList
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                          setemp={setemp}
                          setCurrentTab={setCurrentTab}
                          setHrAccountant={setHrAccountant}
                        />
                      </>
                    ) : (
                      <>
                        <TeamExpenseList
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                          setemp={setemp}
                        />
                      </>
                    )}
                  </>
                )}
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TeamExpense;
