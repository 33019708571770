import { CloseCircle, Document, Eye, TickCircle } from "iconsax-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "../../../Utils/comman";
import { fetchTravelLocalExpense } from "../../../reducers/expenseData";
import ReasonModal from "../../ReasonModal";

function LocalExpView({ data, setLocal }) {
  // const { rowData } = props;
  // console.log(rowData);
  const [show, setShow] = useState(false);
  const [doc, setdoc] = useState([]);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const handleShow = useCallback((docs) => async () => {
    setShow(true);
    setdoc(docs);
  });
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [updatedAmounts, setUpdatedAmounts] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const userRole = userProfile?.user_role[0]?.roleName;
  const dispatch = useDispatch();
  const { expenseData } = useSelector((state) => state.expense);
  const primaryMenuRes = useSelector(
      (state) => state?.primaryMenu?.primaryMenu?.permissions
    );

  const [List, setExpList] = useState([]);
  const [rejectReasonError, setRejectReasonError] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  const [showModalR, setShowModalR] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [remark, setRemark] = useState("");

  useEffect(() => {
    // if (data) {
    const openAccordionId = 0;
    const localID = data.id;
    dispatch(fetchTravelLocalExpense({ openAccordionId, localID }));
    // }
  }, [data]);

  useEffect(() => {
    setExpList(expenseData?.result);
  }, [expenseData]);

  useEffect(() => {
    if (utils.hasPermission(primaryMenuRes, "All Expenses", "all.expenses")) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [primaryMenuRes]);

  const renderExpensesByHead = () => {
    // const { expList } = List;
    // console.log(List);
    const groupedExpenses = List?.reduce((acc, expense) => {
      const { headName } = expense;
      const existingGroup = acc.find((group) => group.headName === headName);

      if (!existingGroup) {
        acc.push({
          headName: headName,
          expenses: [expense],
        });
      } else {
        existingGroup.expenses.push(expense);
      }

      return acc;
    }, []);

    return groupedExpenses;
  };

  const calculateGrandTotal = () => {
    let grandTotal = 0;
    renderExpensesByHead()?.forEach((head) => {
      head.expenses.forEach((expense) => {
        grandTotal += parseFloat(
          expense.subheadname === "Car" || expense.subheadname === "Bike"
            ? expense.tollAmount
            : expense.amount
        );
      });
    });
    return grandTotal.toFixed(2);
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: "image/jpeg" })
      );

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Bills";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      // console.error("Failed to download image:", error);
    }
  };
  useEffect(() => {
    const initialAmounts = {};
    List?.forEach((expense) => {
      initialAmounts[expense.id] = expense.amount;
    });
    setUpdatedAmounts(initialAmounts);
  }, [List]);

  const handleAmountChange = (id, newAmount, expId) => {
    setUpdatedAmounts((prev) => ({
      ...prev,
      [id]: newAmount,
    }));
  };

  const handleBack = () => {
    setLocal(false);
  };

  const handleApprove = useCallback((row) => async () => {
    const formData = new FormData();
    formData.append("type", "local");
    formData.append("expId", row.id);
    formData.append("status", "Approve");
    formData.append("reason", "");

    utils
      .callAPI("post", "/expense/senior-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          //   getTravelExpenses();
          const openAccordionId = 0;
          const localID = data.id;
          dispatch(fetchTravelLocalExpense({ openAccordionId, localID }));
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleReject = useCallback((row) => async () => {
    setSelectedExpense(row);
    setShowRejectModal(true);
  });

  const handleRejectSubmit = async () => {
    if (!rejectReason.trim()) {
      setRejectReasonError("Reject reason cannot be blank");
      return;
    }
    const formData = new FormData();
    formData.append("type", "local");
    formData.append("expId", selectedExpense.id);
    formData.append("status", "Reject");
    formData.append("reason", rejectReason);
    utils
      .callAPI("post", "/expense/senior-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          //   getTravelExpenses();
          setShowRejectModal(false);
          const openAccordionId = 0;
          const localID = data.id;
          dispatch(fetchTravelLocalExpense({ openAccordionId, localID }));
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleUpdateAmount = useCallback((expense) => async () => {
    const updatedAmount = updatedAmounts[expense.id];
    var bodyFormData = new FormData();
    bodyFormData.append("exp_id", expense.id);
    bodyFormData.append("amount", updatedAmount);
    utils
      .callAPI("post", `/expense/amt-edit-hr`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const openAccordionId = 0;
          const localID = data.id;
          dispatch(fetchTravelLocalExpense({ openAccordionId, localID }));
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModalR(true);
    },
    []
  );
  const handleSubmit = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("type", "local");
    bodyFormData.append("id", data?.id);
    bodyFormData.append("submit", "1");
    bodyFormData.append("remark", remark);
    utils
      .callAPI("post", `/expense/submit-hr`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRemark();
          handleBack();
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {List?.length !== 0 ? (
        <>
          {renderExpensesByHead()?.map((head, headIndex) => (
            <RB.Card key={headIndex}>
              <RB.Card.Header>
                <h4>{head.headName}</h4>
                <div className="card-header-action">
                  {/* <RB.Button className="btn btn-underline" onClick={handleBack}>
                    Back
                  </RB.Button> */}
                </div>
              </RB.Card.Header>
              <RB.Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {head.headName === "Local Conveyance" && (
                      <>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>

                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {head.expenses.map((expense, expIndex) => (
                    <tr key={expIndex}>
                      {head.headName === "Local Conveyance" && (
                        <>
                          <td>{expense.subheadname}</td>
                          <td>
                            {moment(expense.dateFrom).format("DD-MM-YYYY")}
                          </td>
                          <td>{expense.description}</td>
                          <td>
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                className="exp-amountInput"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                                disabled={data?.hr_submit == "1"}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? "(Edited by HR)"
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense?.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/team-expense" && (
                            <>
                              {expense.senior_status == "Pending" && (
                                <td>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleApprove(expense)}
                                  >
                                    <TickCircle />
                                  </RB.Button>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleReject(expense)}
                                  >
                                    <CloseCircle />
                                  </RB.Button>
                                </td>
                              )}
                            </>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </RB.Table>
            </RB.Card>
          ))}
          <RB.Row className="justify-content-end align-items-center">
            <RB.Col className="d-flex align-items-center" xs="auto">
              <div className="me-3 d-flex align-items-center">
                <h5 className="d-inline me-2">Total Amount:</h5>
                <h5 className="d-inline">₹ {calculateGrandTotal()}</h5>
              </div>
            </RB.Col>
          </RB.Row>

          {location.pathname == "/expenses/all-expense" && (
            <>
              {data?.hr_submit !== "1" && (
                <>
                  <RB.Form.Group controlId="remark" className="mt-3">
                    <RB.Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </RB.Form.Group>
                  <RB.Button className="mt-2" onClick={handleSubmit}>
                    Submit
                  </RB.Button>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <RB.Row className="justify-content-center mt-1">
          <RB.Col className="text-center">
            <h5>No expenses found.</h5>
          </RB.Col>
        </RB.Row>
      )}

      <RB.Modal show={show} onHide={handleClose} size="lg">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Expense Documents</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row>
            {doc.map((image, index) => (
              <RB.Col md={4} key={index}>
                {image.expense_image.toLowerCase().endsWith(".pdf") ? (
                  <a
                    href={image.expensePath}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product-image-upload-trael"
                  >
                    <div
                      className="position-relative me-2 mb-4 rounded border text-center"
                      style={{ maxWidth: "100%", height: "180px" }}
                    >
                      <div className="position-relative mt-4">
                        <i className="material-icons-outlined md-64">
                          picture_as_pdf
                        </i>
                      </div>
                    </div>
                  </a>
                ) : (
                  <div className="position-relative me-2 mb-4 rounded border text-center">
                    <div className="position-relative">
                      <a
                        // href={image.expensePath}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className="product-image-upload-trael"
                      >
                        <img
                          src={image.expensePath}
                          alt={image.expense_image}
                          className="img-fluid"
                          style={{ maxWidth: "100%", height: "180px" }}
                        />
                      </a>
                      <RB.Button
                        variant="primary"
                        className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                        onClick={() => handleDownload(image.expensePath)}
                      >
                        <i class="material-icons-outlined">file_download</i>
                      </RB.Button>
                    </div>
                  </div>
                )}
              </RB.Col>
            ))}
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>

      <RB.Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Enter Reject Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Form.Control
            as="textarea"
            placeholder="Enter reject reason..."
            value={rejectReason}
            onChange={(e) => {
              setRejectReason(e.target.value);
              setRejectReasonError("");
            }}
          />
          {rejectReasonError && (
            <div className="text-danger mt-2">{rejectReasonError}</div>
          )}
        </RB.Modal.Body>

        <RB.Modal.Footer>
          <RB.Button
            variant="secondary"
            onClick={() => setShowRejectModal(false)}
          >
            Close
          </RB.Button>
          <RB.Button variant="danger" onClick={handleRejectSubmit}>
            Reject
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModalR}
        onHide={() => setShowModalR(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default LocalExpView;
